import React, { useState } from 'react';
import {
  TabContent, TabPane, CardDeck 
} from 'reactstrap';
import FooterComponent from './Footer';
import './Projects.css'
import AloImg from '../assets/projects/alo.jpg';
import BeyoutyImg from '../assets/projects/beyoutygo.jpg';
import BlogBeyoutyImg from '../assets/projects/blog.jpg';
import BoatImg from '../assets/projects/biteknebul.jpg';
import SeyahatImg from '../assets/projects/seyahat.jpg';
import CMFImg from '../assets/projects/cmf.jpg';
import EsteImg from '../assets/projects/este.jpg';
import TeashopImg from '../assets/projects/teashop.jpg';
import RefaeeImg from '../assets/projects/refaee.jpg';
import KhatoonImg from '../assets/projects/khatoon.jpg';
import DehghanImg from '../assets/projects/dehghan.jpg';
import BaroqueImg from '../assets/projects/baroque.jpg';
import FarzamImg from '../assets/projects/farzam.jpg';
import OfoghImg from '../assets/projects/ofogh.jpg';
import TvPlusImg from '../assets/projects/tvplus.jpg';
import OfoghandishanImg from '../assets/projects/ofoghandishan.jpg';
import TalkhandImg from '../assets/projects/talkhand.jpg';
import LilianImg from '../assets/projects/lilian.jpg';
import PpmtImg from '../assets/projects/ppmt.jpg';
import PolymImg from '../assets/projects/polym.jpg';
import PolymProfileImg from '../assets/projects/profile.jpg';
import BiletImg from '../assets/projects/biletking.jpg';
import MJImg from '../assets/projects/mj.jpg';
import SooziImg from '../assets/projects/soozi.jpg';
import PcrImg from '../assets/projects/pcr.jpg';
import CodeFlowImg from '../assets/projects/codeflow.jpg';
import MoeImg from '../assets/projects/moe.jpg';
import PicDepthImg from '../assets/projects/picdepth.jpg';
import VizeImg from '../assets/projects/vize.png';
import CoorestImg from '../assets/projects/Coorest.png';
import CardComponent from './Card';



/* Receive props: backgroundColor */
const ProjectsComponent = (props) => {

  const [activeTab, setActiveTab] = useState('1');



  // To collapse nav
  const [isOpen, setIsOpen] = useState(false);
  const navOpen = () => setIsOpen(!isOpen);
  let windowWidth;
  const resetOpen = () => {
    windowWidth = window.innerWidth;
    if (windowWidth >= 768) setIsOpen(false);
  }
  window.addEventListener('resize', resetOpen);

  return (
    <div style={{ backgroundColor: props.backgroundColor }} className="container-projects">
      <div className="d-flex justify-content-center" style={{ paddingBottom: 20 }}>
        <div className="animated-underline">
          <h2>Projects</h2>
        </div>
      </div>

      {/* Border radius in div will not be effective, must also be in navbar (?) */}
      {/* Trade-off:  40vmax gives better consistency at 1080p (android tab is smaller due to image width at 65vmax), but 65vmax gives better responsivenss*/}
      <div style={{ maxWidth: '65vmax', margin: 'auto', backgroundColor: 'white', borderRadius: 25, marginBottom: 200,padding:20 }}>
       
        <TabContent activeTab={activeTab} className="d-flex justify-content-center ">
          <TabPane tabId="1">
            <CardDeck>

            <CardComponent  
                 image={VizeImg}
                alt="Vize Evraklari"
                title="Vize Evraklari"
                subtitle={"Wordpress \n "}
                text={"This project is designed to assist students and travelers in effectively managing their academic commitments and travel plans. Its goal is to create a user-friendly platform that helps users avoid scheduling conflicts, track visa requrments, and ensure a seamless travel experience."}
                livesite="https://vizeevraklari.com"
              />

            <CardComponent  
                 image={CoorestImg}
                alt="Coorest"
                title="Coorest"
                subtitle={"React.js \n "}
                text={"I collaborated with another front-end team member on this project, working together for a short duration. This experience proved to be incredibly enriching as it exposed me to Web 3 and Moralis technologies, significantly enhancing my proficiency in these domains."}
                livesite="https://app.coorest.io"
              />

            <CardComponent  
                 image={PicDepthImg}
                alt="Pic Depth"
                title="Pic Depth"
                subtitle={"Wordpress \n "}
                text={"The website of an architect design company is built on WordPress, providing visitors with a visually captivating platform to explore their portfolio of projects."}
                livesite="https://picdepth.com"
              />

              <CardComponent  
                 image={BoatImg}
                alt="Biteknebul"
                title="Biteknebul"
                subtitle={"Laravel | VUE.JS  \n "}
                text={"One of my proudest projects is this one. The website allows you to search for and book boats and ships. It also lets boat owners manage their boats and accept or reject booking requests. "
               +" Moreover, I developed an API for the mobile section so IOS and Android versions are available.  \n "}
                livesite="https://biteknebul.com"
              />

              <CardComponent
               image={SeyahatImg}
                alt="Seyahat merkezi"
                title="Seyahat merkezi"
                subtitle={"PHP | Bootstrap | JavaScript\n "}
                text={"This is a travel agency website that incorporates Sabre, Amadeus, and Travel Payouts to retrieve flight details and make a booking from the website "
               +" I used APIs from Expedia, Hotelbeds, and TP Hotels to find available hotels and prices around the world "
               +" Through the Viator API, I integrated a world-wide tour search into our website. To get hotels' reviews, I used the Trip Advisor.  \n "}
                livesite="https://seyahatmerkezi.com"
              />
                <CardComponent
                image={EsteImg}
                alt="Esteverse"
                title="Esteverse"
                subtitle={"PHP | JS | CSS \n"}
                text={"This is a Health Tourism website that related to our main company to invite people into Turkey and do their health surgical . \n "}
                livesite="https://esteverse.com"
              /> 
  
                <CardComponent
                image={BeyoutyImg}
                alt="Beyoutygo"
                title="Beyoutygo"
                subtitle={"Laravel \n"}
                text={"This is an ondemand service which offer Manicure, Pedicure, Health care, Nails, Hair and etc in Turkey\n "}
                livesite="https://beyoutygo.com"
              />  

              <CardComponent
                image={BlogBeyoutyImg}
                alt="Beyoutygo Blog"
                title="Beyoutygo Blog"
                subtitle={"wordpress \n"}
                text={"This is a blog for beyoutygo service which offer Manicure, Pedicure, Health care, Nails, Hair and etc in Turkey\n "}
                livesite="https://blog.beyoutygo.com"
              />  
              
              <CardComponent
                image={BiletImg}
                alt="Bilet King"
                title="Bilet King"
                subtitle={"Laravel | VUE.JS \n"}
                text={"This is a website that provides tickets for events, concerts, etc. In Turkey\n "}
                livesite="https://biletking.com"
              />

              <CardComponent
                 image={CMFImg}
                alt="CMF export"
                title="CMF export"
                subtitle={"PHP | React \n "}
                text={"It is a transportation,cargo company located in North Cyprus. Customers can request cargo online and track their packages online with this service  \n "}
                livesite="https://www.cmfexport.com/"

              />

              <CardComponent
                 image={PcrImg}
                alt="Covid Pcr Test Service "
                title="Covid Pcr Test Service"
                subtitle={"wordpress | woocommerce \n "}
                text={"This e-commerce project was built using WordPress and WooCommerce, For the frontend, I took their idea and developed a theme based on their preferences. Using Elementor, they can change the sections as required.  \n "}
                

              />
        
            <CardComponent
                 image={AloImg}
                alt="ALO TURKEY "
                title="ALO TURKEY"
                subtitle={"wordpress \n "}
                text={"A Turkish real estate and investment company. I developed a user interface based on the Enfold theme and developed a plugin that allows users to see their real estate listings by category and city   \n "}
                livesite="https://aloturkey.org"

              /> 

              <CardComponent
                 image={CodeFlowImg}
                alt="Code Flow "
                title="Code Flow"
                subtitle={"wordpress | Learnpress \n "}
                text={"This e-learning project was built using WordPress and learnpress, For the frontend, I took their idea and developed a theme based on their preferences. Using Elementor, they can change the sections as required. \n "}
               

              /> 

              <CardComponent
                 image={MoeImg}
                alt="Moe Taslimi"
                title="Moe Taslimi"
                subtitle={"wordpress \n "}
                text={"For a Canadian real estate company, I undertook a comprehensive project.  I designed the user interface from the ground up and developed the backend to enable users to seamlessly browse property listings by city and category, with certain exclusive lists accessible via login. Additionally, I created a custom plugin to connect with The Canadian Real Estate Association (CREA) API, allowing for real-time importing of listings from crea.ca into the website's real estate plugin.\n "}
                livesite="https://realestate.kohan.com.tr"

              /> 

              <CardComponent
                 image={MJImg}
                alt="Majid Drums "
                title="Majid Drums"
                subtitle={"wordpress | woocommerce \n "}
                text={"This e-commerce project was built using WordPress and WooCommerce, For the frontend, I took their idea and developed a theme based on their preferences. Using Elementor, they can change the sections as required.  \n "}
                livesite="https://majiddrums.com/"

              /> 

              <CardComponent
                 image={TeashopImg}
                alt="IR Tea Shop"
                title="IR Tea Shop"
                subtitle={"wordpress | woocommerce \n "}
                text={"This e-commerce project was built using WordPress and WooCommerce, and I developed a "
               + "plugin to integrate their inventory and accounting system to show real-time quantity and price on the website." 
               +" For the frontend, I took their idea and developed a theme based on their preferences. Using Elementor, they can change the sections as required.  \n "}
                livesite="https://irteashop.ir"

              />

              <CardComponent
                 image={RefaeeImg}
                alt="Refaee"
                title="Refaee"
                subtitle={"wordpress | woocommerce | elarn press \n "}
                text={"This e-commerce project was built using WordPress and WooCommerce, For the frontend, I took their idea and developed a theme based on their preferences. Using Elementor, they can change the sections as required. \n "}
                livesite="https://refaee.com"

              />

              <CardComponent
                image={KhatoonImg}
                alt="Khatoon Advertising"
                title="Khatoon Advertising"
                subtitle={"wordpress | woocommerce  \n "}
                text={"It was a WordPress-based website to show off our company's portfolio to customers  \n "}
                livesite="https://khatoonadvertising.ir"

              />

              <CardComponent
                image={DehghanImg}
                alt="Malihe Dehghan"
                title="Malihe Dehghan"
                subtitle={"wordpress  \n "}
                text={"WordPress based website for a photographer who wants to show off her portfolio to her clients. She was looking for clear and minimal interfaces   \n "}
                livesite="https://malihedehghan.com "

              />

              <CardComponent
                image={BaroqueImg}
                alt="Baroque Group"
                title="Baroque Group"
                subtitle={"wordpress \n "}
                text={"The website of a construction company was built on WordPress. Visitors are able to see their projects on the site.  \n "}
               

              />


              <CardComponent
                 image={FarzamImg}
                alt="Farzam Mohaseb"
                title="Farzam Mohaseb"
                subtitle={"wordpress \n "}
                text={"WordPress website for an accounting company to introduce themselves on the Internet. Like other projects I created a theme for them from scratch   \n "}

              />

              <CardComponent
                image={OfoghImg}
                alt="Ofogh Mashhad"
                title="Ofogh Mashhad"
                subtitle={"wordpress \n "}
                text={"The Ofogh Mashhad publication company has some websites that I created themes for them in WordPress \n "}
                livesite="https://Ofoghmashhad.ir"
              />

              <CardComponent
                image={TvPlusImg}
                alt="Mashhad TV Plus"
                title="Mashhad TV Plus"
                subtitle={"wordpress \n "}
                text={"The Mashhad tv plus is video news website   \n "}
                livesite="https://mashhadtvplus.ir"
              />

              <CardComponent
                image={OfoghandishanImg}
                alt="Ofogh Andishan Mashhad"
                title="Ofogh Andishan Mashhad"
                subtitle={"wordpress  \n "}
                text={"The Ofogh Mashhad publication company has some websites that I created themes for them in WordPress   \n "}
                livesite="https://ofoghandishanmashhad.ir"
              />

              <CardComponent
                image={TalkhandImg}
                alt="Talkhand Mashhad"
                title="Talkhand Mashhad"
                subtitle={"wordpress \n "}
                text={"The Ofogh Mashhad publication company has some websites that I created themes for them in WordPress   \n "}
                livesite="https://talkhandmashhad.ir"
              /> 
              
              <CardComponent
                image={LilianImg}
                alt="Lilian Mode"
                title="Lilian Mode"
                subtitle={"wordpress | woocommerce \n "}
                text={"This e-commerce project was built using WordPress and WooCommerce, and I developed a "
                + "plugin to integrate their inventory and accounting system to show real-time quantity and price on the website." 
                +" For the frontend, I took their idea and developed a theme based on their preferences. Using Elementor, they can change the sections as required.  \n "}
                livesite="https://lilianmode.ir"
              />

              <CardComponent
                image={PpmtImg}
                alt="PPMT"
                title="PPMT"
                subtitle={"wordpress \n "}
                text={"The website of a construction company was built on WordPress. Visitors are able to see their projects on the site.  \n "}
              />

              <CardComponent
                image={PolymImg}
                alt="Polym Part"
                title="Polym Part"
                subtitle={"wordpress \n "}
                text={"The Polymer reference website in the Iranian market was built on WordPress. Visitors are able to see their news and polymer's price on the site.  \n "}
                livesite="https://polympart.com"
              />

              <CardComponent
                image={PolymProfileImg}
                alt="Polym Part Classified"
                title="Polym Part Classified"
                subtitle={"wordpress  \n "}
                text={"The Polymer classified website in the  able to see other companies ads their price on the site.  \n "}
              />
   <CardComponent
                image={SooziImg}
                alt="Soozi Land"
                title="Soozi Land"
                subtitle={"wordpress | woocommerce \n "}
                text={"This e-commerce project was built using WordPress and WooCommerce, For the frontend, I took their idea and developed a theme based on their preferences. Using Elementor, they can change the sections as required.  \n "}
                livesite="https://sooziland.com"
              />
            
            </CardDeck>
            
          </TabPane>
        </TabContent>
      </div>

      <FooterComponent />
    </div>
  );
}

export default ProjectsComponent;
