import React from 'react';
import { Chrono } from "react-chrono";
import './Experience.css';

/* Receive props: backgroundColor */
const ExperienceComponent = (props) => {

  const items = [
    {
      title: "(06/2022) – Present",
      cardTitle: "Fullstack Developer",
      url: "https://www.ecenglish.com",
      cardSubtitle: "EC English | Malta",
      cardDetailedText: " Working as a web developer with a strong passion for crafting innovative solutions to enhance the online presence of EC English. With a diverse skill set and a commitment to excellence, I specialize in creating custom plugins and integrations tailored to the specific needs of EC English's digital ecosystem.      ."
      + "WordPress Plugins: Creating tailored plugins for a better website experience."
      + "HubSpot Integrations: Streamlining data and marketing efforts."
      + "PHP Laravel Projects: Developing robust, custom web applications. With a commitment to innovation and precision, I enhance EC English's online presence. "
    
    },
    {
      title: "(03/2022) – (06/2022)",
      cardTitle: "Frontend Developer | Part time",
      url: "https://www.coorest.io",
      cardSubtitle: "Coorest | Estonia",
      cardDetailedText: " I collaborated with another front-end team member on this project, working together for a short duration. This experience proved to be incredibly enriching as it exposed me to Web 3 and Moralis technologies, significantly enhancing my proficiency in these domains. "
     },
    {
    title: "(03/2021) – (06/2022)",
    cardTitle: "Full Stack Developer",
    url: "https://www.seyahatmerkezi.com",
    cardSubtitle: "Seyahat Merkezi | Turkey",
    cardDetailedText: " Created websites using PHP Laravel, Vue js, and created API for mobile  application. "
    + "Enhanced user experience and accomplish webpage objectives by creating site structure, "
    + "navigation, page optimization, and graphics integration. "
    + "Implemented enhancements that improved web functionality and responsiveness. "
  },
  {
    title: "(04/2020) – (02/2021)",
    cardTitle: "Wordpress Developer",
    url: "https://khatoonadvertising.ir",
    cardSubtitle: " Khatoon Advertising Agency | Iran",
     cardDetailedText: " Manage website development projects from initial design through completion, optimizing all cross-browser and multi-platform compatibility."
     + "Work closely with programmers and clients to meet project requirements, goals, and desired functionality." 
     + "Developed customized themes and plugins into WordPress, "
     + "Conduct training for clients on handling website content management systems. ",  },
  {
    title: "(04/2019) – (03/2020) ",
    cardTitle: "Web Developer",
    cardSubtitle: "KNT Real Estate & Investment | Turkey",
    cardDetailedText:"For their WordPress-based website, I developed plugin to show their properties (like real estate websites), also i created theme based by their company’s identity " 
     + "Technologies: Wordpress, PHP , mysql, CSS,HTML5 "
     + "Live website: kntinvestment.com.tr (Currently their website is down) ",
  },
  {
    title: "(11/2018) - (04/2019) ",
    cardTitle: "Wordpress Developer",
    cardSubtitle: "King group istanbul | Turkey",
    // cardDetailedText: jobDescription["grader"],
  },
  {
    title: "(10/2015) – (11/2018) ",
    cardTitle: "Web developer and launch team leader",
    url: "https://saanaa.ir",
    cardSubtitle: "Sana Sobhan Tejarat | Iran",
    cardDetailedText: " I was part of a team that developed/launched system for national insurance to integrate their branches with their hospital using ASP.NET (HIS for Tamin Ejtemaee in Iran)",
  },
  {
    title: "(05/2012) – (06/2015) ",
    cardTitle: "Web developer",
    cardSubtitle: " Farahang Advertising Agency | Iran",
    cardDetailedText: "I Established an exclusive CMS using Asp.net webform for clients and corporate websites.  On 2013, we created the first live stream in Iran for one of our clients (Padideh Shandiz Holding) "
      + "Farahangmedia.com "
      + "Hyundaiiran.com "
      + "Padide.com "
      + "Modernhomemall.ir ",
  },
  
];

  return (
    <div style={{backgroundColor: props.backgroundColor}} className="container-experience">
      <div className="d-flex justify-content-center">
        <div className="animated-underline">
          <h2> Experience</h2>
        </div>
      </div>
      <div style={{ marginLeft: "4.5vw", paddingBottom: 140, width: "90vw" }}>
        <Chrono items={items} mode="VERTICAL" enableOutline slideShow slideItemDuration={4500} scrollable slideShowType="reveal"/>
      </div>

    </div>
  );
}

export default ExperienceComponent;
