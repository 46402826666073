import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// extension ityped has mem leaks (?)
import Typed from 'typed.js';
import './About.css';
import './Utils.css';
import AboutImage from '../assets/about/5-main.svg';
import EmailImage from '../assets/about/2-email.svg';
import GitHubImage from '../assets/about/3-github.svg';
import LinkedInImage from '../assets/about/4-linkedin.svg';
import TelegramImage from '../assets/about/6-telegram.svg';
import InstagramImage from '../assets/about/7-instagram.svg';
import MRKLogo from '../assets/about/mrk.png';

/* Receive props: backgroundColor */
const AboutComponent = (props) => {

	// Create reference to store the DOM element containing the animation
	const el = React.useRef(null);
  // Create reference to store the Typed instance itself
	const typed = React.useRef(null);

  React.useEffect(() => {
    const options = {
    	strings: [
        'Front-end developer',
        'Backend developer',
        'Wordpress developer'
      ],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };
    
    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);
    
    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    }
  }, [])

  return (
    <div style={{ backgroundColor: props.backgroundColor }} className="d-flex justify-content-around flex-wrap">
      <div style={{ marginLeft: 20, marginTop: 40, display: 'inline-flex' }} className="flex-column">
      <img src={MRKLogo} alt="Mohammad reza kohan" width="150" />
        <div className="animated-underline mt-3">
          <h1>Hi, I'm Mohammadreza Kohan</h1>
          <h2 style={{display: 'inline'}}><span style={{ whiteSpace: 'pre', color: '#0f52ba' }} ref={el} /></h2>
        </div>
        
        {/* Description */}
        <p className="content">I'm a passionate developer with a keen interest in web platforms. My enthusiasm lies in harnessing web technologies to craft innovative solutions for real-world challenges. To achieve this, I constantly strive to expand my knowledge in both frontend and backend development simultaneously.</p>
        {/* Contacts */}

        <div>
          <a href="https://www.linkedin.com/in/mrkohan/">
            <img src={LinkedInImage} className="icons" alt="LinkedIn icon" />
          </a>
          <a href="https://github.com/mrkohan">
            <img src={GitHubImage} className="icons" alt="GitHub icon" />
          </a>
          <a href="mailto:mrkohan@live.com">
            <img src={EmailImage} className="icons" alt="Live icon" />
          </a>  
           <a href="https://t.me/mrkohan">
            <img src={TelegramImage} className="icons" alt="Live icon" />
          </a> 
           <a href="https://instagram.com/mrezakohan">
            <img src={InstagramImage} className="icons" alt="Live icon" />
          </a>
        </div>
      
        <Container style={{ marginLeft: -15, paddingBottom: 120,marginTop: 60 }}>
          <Row>
            <Col>
              <em>

  <img align="center" alt="github status" src="https://github-readme-stats.vercel.app/api/top-langs/?username=mrkohan&layout=compact" />

              </em>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <p>BS, Computer Science and Systems</p>
            </Col>
            <Col>
              <p>06/2021</p>
            </Col>
          </Row> */}
        
        
         
       
        </Container>
      </div>
      <img src={AboutImage} className="illustration" alt="Illustration of devices on different platfroms." />
    </div>
  );
}

export default AboutComponent;